<template>
  <a-list
    item-layout="vertical"
    size="small"
    :pagination="pagination"
    :data-source="listData"
  >
    <a-list-item
      slot="renderItem"
      key="item.title"
      slot-scope="item"
      style="display: flex; height: 62px"
    >
      <div style="flex: 1 0; font-size: 20px">
        <router-link
          :to="{
            name: '文章',
            params: { articleid: item.neinId, fromPath },
          }"
          style="color: #f6de8d"
          >{{ item.title }}</router-link
        >
      </div>
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: left;
          color: #f6de8d;
        "
      >
        {{ item.time }}
      </div>
    </a-list-item>
  </a-list>
</template>

<script>
import request from "@/utils/request";

export default {
  data() {
    return {
      listData: [],
      pagination: {
        onChange: (page) => {
          console.log(page);
        },
        pageSize: 10,
        // showQuickJumper: true,
      },
      fromPath: "",
    };
  },
  mounted() {
    this.fromPath = this.$route.name;
    var that = this;
    request({
      url:
        "/column/getCategoryList?cateName=%E5%85%9A%E5%91%98%E4%BF%A1%E6%81%AF%E5%8C%96%E7%AE%A1%E7%90%86",
      method: "GET",
      params: null,
    }).then((res) => {
      // console.log("res= ",res)
      console.log(res.data.data);
      res.data.data.forEach(function (value, index) {
        console.log(index);
        if (index == 3) {
          console.log(value);
          //"home_scroll_top"  图片，

          value.children.forEach(function (value2, index2) {
            console.log(value2, index2);
            var item = new Object();
            item.title = value2.neinNewsname;
            item.time = value2.neinStartdate;
            item.neinId = value2.neinId;
            that.listData.push(item);
          });
        }
      });
    });
  },
};
</script>


<style>
.ant-spin-nested-loading {
  border-radius: 10px;
  padding: 15px;
  background: rgba(255, 255, 255, 0.3);
}

.ant-list-something-after-last-item
  .ant-spin-container
  > .ant-list-items
  > .ant-list-item:last-child {
  border-bottom: 1px dashed #e8e8e8;
}

.ant-list-split .ant-list-item {
  border-bottom: 1px dashed #e8e8e8;
}

.ant-pagination-item-link {
  background: rgba(255, 255, 255, 0.3) !important;
}

.ant-pagination-item-link:focus,
.ant-pagination-item-link:hover {
  color: #f6de8d !important;
  border-color: #f6de8d !important;
}

.ant-pagination-item-link a {
  color: #f6de8d !important;
}

.ant-pagination-item {
  background: rgba(255, 255, 255, 0.3);
}

.ant-pagination-item-active {
  border-color: #f6de8d !important;
  background: rgba(255, 255, 255, 0.3);
}

.ant-pagination-item-active a {
  color: #f6de8d !important;
}

.ant-pagination-item-active:focus a,
.ant-pagination-item-active:hover a {
  color: #f6de8d !important;
}

.ant-pagination-item:focus a,
.ant-pagination-item:hover a {
  color: #f6de8d !important;
}
.ant-pagination-item:focus,
.ant-pagination-item:hover {
  border-color: #f6de8d !important;
  background: rgba(255, 255, 255, 0.3);
}

.ant-list a:active,
.ant-list a:hover {
  text-decoration: none;
  outline: 0;
  color: black !important;
}

.ant-list-pagination {
  text-align: center !important;
}

.ant-pagination-options-quick-jumper {
  color: #f6de8d;
}

.ant-pagination-options-quick-jumper input {
  color: rgba(246, 222, 141, 0.3);
}
</style>
